<template>
  <v-row class="px-3 pb-3" dense>
    <v-col cols="12" sm="6" md="3">
      <BaseDatepickerInput
        v-model="filters.date_from"
        :label="$t('date_from')"
      />
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <BaseDatepickerInput
        v-model="filters.date_to"
        :label="$t('date_to')"
      />
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <BaseAutocomplete
        v-if="$isAdmin()"
        v-model="selectedUser"
        :search-function="userSearchFunction"
        :label="$t('user')"
        item-text="full_name"
        clearable
        @input="setUserId"
      />
    </v-col>
    <v-col cols="12" sm="6" md="3" class="d-flex justify-end align-center">
      <v-btn
        color="primary"
        text
        @click="resetFilters"
      >
        <v-icon left>mdi-filter-variant-remove</v-icon>
        {{ $t('clear_filters') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import BaseAutocomplete from './base/BaseAutocomplete';
import userService from '../api/user-service';
import BaseDatepickerInput from './base/BaseDatepickerInput';

export default {
  name: 'CheckInFilter',

  components: { BaseDatepickerInput, BaseAutocomplete },

  data() {
    return {
      defaultFilters: {
        user_id: null,
        date_from: null,
        date_to: null,
      },
      filters: {},
      selectedUser: null,
      userSearchFunction: userService.search,
      timerID: null,
    };
  },

  created() {
    this.resetFilters();
    this.$watch('filters', this.updateFilter, { deep: true });
  },

  methods: {
    updateFilter(val) {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.$emit('change', val);
      }, 500);
    },

    resetFilters() {
      this.filters = { ...this.defaultFilters };
    },

    setUserId(user) {
      this.filters.user_id = user ? user.id : null;
    },
  },
};
</script>

<style scoped>

</style>
