<template>
  <div class="page-wrapper">
    <CheckInTable
      :loading="isDataLoading"
      :rows="checkInArray"
      :pagination="checkInPagination"
      :show-button-loader="isLocationLoading"
      @view="crudMixin_openForm('checkIn', $event)"
      @new-item="getUserLocation"
    >
      <template v-slot:append-top>
        <CheckInFilter @change="getPaginatedCheckIns(1, $event)"/>
      </template>
    </CheckInTable>

    <v-dialog
      v-model="isCheckInFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      scrollable
    >
      <CheckInForm
        :dialog="isCheckInFormOpen"
        :form-item="checkInFormItem"
        @create="crudMixin_created('checkIn', $event)"
        @update="crudMixin_updated('checkIn', $event)"
        @cancel="isCheckInFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import crudMixin from '../mixins/crud-mixin';
import checkInService from '../api/check-in-service';
import CheckInTable from '../components/tables/CheckInTable';
import CheckInForm from '../components/forms/CheckInForm';
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';
import CheckInFilter from '../components/CheckInFilter';

export default {
  name: 'CheckIns',

  components: { CheckInFilter, CheckInForm, CheckInTable },

  mixins: [crudMixin],

  data() {
    return {
      checkInArray: [],
      checkInFilterParams: {},
      checkInFormItem: {},
      checkInPagination: {},
      isCheckInFormOpen: false,
      isDataLoading: true,
      isLocationLoading: false,
    };
  },

  created() {
    this.getPaginatedCheckIns(1, null);
  },

  methods: {
    getPaginatedCheckIns(pageNo, params) {
      if (params) {
        this.checkInFilterParams = params;
      }
      this.crudMixin_getPage(
        checkInService.getPage,
        checkInService.model,
        pageNo,
        this.checkInFilterParams,
      );
    },

    getUserLocation() {
      if (!navigator.geolocation) {
        eventBus.$emit(OPEN_SNACKBAR, this.$t('geolocation_is_not_supported_by_this_browser_or_device'));
        return;
      }
      this.isLocationLoading = true;
      navigator.geolocation.getCurrentPosition(this.newCheckIn, this.onGeolocationError);
    },

    newCheckIn(position) {
      this.isLocationLoading = false;
      if (!position?.coords) {
        return;
      }
      console.log(position);
      this.crudMixin_openForm('checkIn', {
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      });
    },

    onGeolocationError(error) {
      this.isLocationLoading = false;
      let msg = '';
      switch (error.code) {
        case error.PERMISSION_DENIED:
          msg = 'user_denied_geolocation_request';
          break;
        case error.POSITION_UNAVAILABLE:
          msg = 'geolocation_unavailable';
          break;
        case error.TIMEOUT:
          msg = 'geolocation_request_timed_out';
          break;
        default:
          msg = 'geolocation_failed';
      }
      eventBus.$emit(OPEN_SNACKBAR, this.$t(msg));
    },
  },
};
</script>
