<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="decoratedRows"
      :mobile-breakpoint="0"
      disable-pagination
      disable-sort
      hide-default-footer
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:top>
        <BaseTableHeader
          :title="$t('check_ins')"
          :create-button="$t('check_in')"
          :show-button-loader="showButtonLoader"
          create-button-icon="mdi-map-marker-plus"
          @new-item="$emit('new-item')"
        />
        <slot name="append-top"/>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          :actions="actions"
          :item="item"
        />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        />
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseTableHeader from '../base/BaseTableHeader';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';

export default {
  name: 'CheckInTable',

  components: {
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseTableHeader,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    showButtonLoader: Boolean,
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('view', p),
          label: this.$t('show_on_map'),
          icon: 'mdi-map-marker',
        },
      ],
      headers: [
        {
          text: this.$t('user'),
          value: 'userName',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('date'),
          value: 'created_at',
        },
        {
          text: this.$t('lat'),
          value: 'lat',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('lng'),
          value: 'lng',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
        },
      ],
    };
  },

  created() {
    if (!this.$isAdmin()) {
      this.headers.splice(0, 1);
    }
  },

  computed: {
    decoratedRows() {
      return this.rows.map(r => ({
        ...r,
        userName: r.user?.person?.full_name,
      }));
    },
  },
};
</script>
