import http from './http';

const endpoint = 'api/check-ins';

const checkInService = {
  model: 'checkIn',

  getPage: (page, params) => http.get(`${endpoint}?page=${page}`, { params }),

  create: checkIn => http.post(endpoint, checkIn),
};

export default checkInService;
