<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        <template v-if="!formItem.id">
          {{ $t('confirm_location') }}
        </template>
        <template v-else>
          {{ formItem.userName }}
          <span class="ml-2 grey--text">{{ formItem.created_at }}</span>
        </template>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <iframe v-if="mapUrl" :src="mapUrl" frameborder="0" class="g-maps-iframe"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click.native="$emit('cancel')"
        >
          {{ $t(formItem.id ? 'close' : 'cancel') }}
        </v-btn>
        <v-btn
          v-if="!formItem.id"
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import checkInService from '../../api/check-in-service';

export default {
  name: 'CheckInForm',

  mixins: [crudMixin, dialogMixin],

  props: {
    formItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isRequestPending: false,
    };
  },

  computed: {
    mapUrl() {
      const { lat, lng } = this.formItem;
      if (!lat || !lng) {
        return '';
      }
      return `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_CLOUD_VISION_API_KEY}&q=${lat},${lng}`;
    },
  },

  methods: {
    onSubmit() {
      this.crudMixin_create(
        checkInService.create,
        checkInService.model,
        this.formItem,
        null,
        this.$t('check_in_failed'),
      );
    },
  },
};
</script>

<style scoped>
  .g-maps-iframe {
    width: 100%;
    height: 500px;
  }
</style>
